var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('table-primary', {
    attrs: {
      "caption": ""
    }
  }, [_c('table-row', {
    attrs: {
      "title": "아이디",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.rules.username,
      "placeholder": "영문,숫자 포함 3자 이상 입력해주세요.",
      "outlined": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.username,
      callback: function ($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "비밀번호",
      "pointer": ""
    }
  }, [_c('v-password-field', {
    attrs: {
      "rules": _vm.rules.password,
      "autocomplete": "new-password",
      "placeholder": "영문,숫자,특수기호 포함 8자 입력해주세요.",
      "outlined": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "비밀번호 확인",
      "pointer": ""
    }
  }, [_c('v-password-confirm-field', {
    attrs: {
      "password": _vm.form.password,
      "placeholder": "동일한 비밀번호를 재입력하세요.",
      "outlined": ""
    },
    on: {
      "input": _vm.emit
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "이름",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "disabled": "",
      "placeholder": "이름을 입력하세요.",
      "outlined": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "닉네임",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.rules.nickname,
      "placeholder": "닉네임을 입력하세요.",
      "outlined": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nickname", $$v);
      },
      expression: "form.nickname"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "성별",
      "pointer": ""
    }
  }, [_c('v-gender-radio-group', {
    model: {
      value: _vm.form.gender,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "연락처",
      "pointer": ""
    }
  }, [_c('v-phone-field', {
    attrs: {
      "disabled": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "생년월일"
    }
  }, [_c('v-text-field', {
    attrs: {
      "disabled": "",
      "outlined": "",
      "placeholder": "생년월일 6자리를 입력하세요. (ex.940118)"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.birthday,
      callback: function ($$v) {
        _vm.$set(_vm.form, "birthday", $$v);
      },
      expression: "form.birthday"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "이메일",
      "pointer": ""
    }
  }, [_c('v-email-field', {
    attrs: {
      "rules": _vm.rules.email
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "행사 및 관련 소식 <br class='d-none d-md-block' />수신여부"
    }
  }, [_c('v-news-radio-group', {
    model: {
      value: _vm.form.marketingMethod,
      callback: function ($$v) {
        _vm.$set(_vm.form, "marketingMethod", $$v);
      },
      expression: "form.marketingMethod"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "주소"
    }
  }, [_c('v-address-field', {
    attrs: {
      "noRules": true
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1)], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "outlined": "",
      "rounded": "",
      "color": "grey lighten-4"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("취소")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "rounded": "",
      "dark": "",
      "color": "primary",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.join
    }
  }, [_c('span', [_vm._v("가입하기")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }