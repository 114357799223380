<template>
    <v-form v-model="isValid">
        <table-primary caption>
            <table-row title="아이디" pointer>
                <v-text-field v-model="form.username" :rules="rules.username" placeholder="영문,숫자 포함 3자 이상 입력해주세요." outlined @input="emit" />
            </table-row>
            <table-row title="비밀번호" pointer>
                <v-password-field v-model="form.password" :rules="rules.password" autocomplete="new-password" placeholder="영문,숫자,특수기호 포함 8자 입력해주세요." outlined @input="emit" />
            </table-row>
            <table-row title="비밀번호 확인" pointer>
                <!-- <v-text-field v-model="form.passwordConfirm" type="password" placeholder="동일한 비밀번호를 재입력하세요." outlined  @input="emit" /> -->
                <v-password-confirm-field :password="form.password" placeholder="동일한 비밀번호를 재입력하세요." outlined @input="emit" />
            </table-row>
            <table-row title="이름" pointer>
                <v-text-field v-model="form.name" disabled placeholder="이름을 입력하세요." outlined @input="emit" />
            </table-row>
            <table-row title="닉네임" pointer>
                <v-text-field v-model="form.nickname" :rules="rules.nickname" placeholder="닉네임을 입력하세요." outlined @input="emit" />
            </table-row>
            <table-row title="성별" pointer>
                <v-gender-radio-group v-model="form.gender" />
            </table-row>
            <table-row title="연락처" pointer>
                <v-phone-field v-model="form.phone" disabled @input="emit" />
            </table-row>
            <table-row title="생년월일">
                <v-text-field v-model="form.birthday" disabled outlined placeholder="생년월일 6자리를 입력하세요. (ex.940118)" @input="emit" />
            </table-row>
            <table-row title="이메일" pointer>
                <v-email-field v-model="form.email" :rules="rules.email" @input="emit" />
            </table-row>
            <table-row title="행사 및 관련 소식 <br class='d-none d-md-block' />수신여부">
                <v-news-radio-group v-model="form.marketingMethod" />
            </table-row>
            <table-row title="주소">
                <v-address-field v-model="form.address" :noRules="true" @input="emit" />
            </table-row>
        </table-primary>

        <!-- 하단버튼 -->
        <div class="v-btn--group">
            <v-row justify="center" class="row--x-small">
                <v-col cols="auto">
                    <v-btn large outlined rounded color="grey lighten-4" class="rounded-xs" @click="$router.go(-1)">
                        <span class="grey--text">취소</span>
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn large rounded dark color="primary" class="rounded-xs" :disabled="disabled" @click="join">
                        <span>가입하기</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<script>
import { initUser, rules } from "@/assets/variables";

import TableRow from "@/components/dumb/table-row.vue";
import TablePrimary from "@/components/dumb/table-primary.vue";

import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import VEmailField from "@/components/plugins/vuetify/v-email-field.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VGenderRadioGroup from "@/components/plugins/vuetify/v-gender-radio-group.vue";
import VNewsRadioGroup from "@/components/plugins/vuetify/v-news-radio-group.vue";

export default {
    components: {
        TableRow,
        TablePrimary,
        VPasswordConfirmField,
        VPasswordField,
        VPhoneField,
        VEmailField,
        VAddressField,
        VGenderRadioGroup,
        VNewsRadioGroup,
    },
    props: {
        value: { type: Object, default: initUser },
    },
    data: () => ({
        rules,
        form: initUser(),

        isValid: false,
    }),
    computed: {
        disabled() {
            return !this.isValid;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUser(this.value);
        },
        emit() {
            this.form = initUser(this.form);
            // this.$emit("input", this.form);
        },

        join() {
            try {
                this.validate();
                this.$emit("submit", this.form);
            } catch (error) {
                alert(error.message);
            }
        },

        validate() {
            if (!this.form.username) throw new Error("아이디를 입력해주세요.");
            if (!this.form.password) throw new Error("비밀번호를 입력해주세요.");

            if (!this.form.name) throw new Error("이름을 입력해주세요.");
            if (!this.form.nickname) throw new Error("닉네임을 입력해주세요.");
            if (!this.form.gender) throw new Error("성별을 선택해주세요.");

            if (!this.form.phone) throw new Error("휴대전화를 입력해주세요.");
            if (!this.form.email) throw new Error("이메일을 입력해주세요.");
        },

        // pullInsurancePolicy(index) {
        //     this.form.blacksmith.insurance.policies.splice(index, 1);
        //     this.emit();
        // },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-radio .v-label {
        margin-top: initial;
    }
    .v-text-field {
        height: auto !important;
        min-height: 36px !important;
        .v-input__slot {
            margin-bottom: 0 !important;
            min-height: initial !important;
        }
        .v-messages__message {
            margin-bottom: 4px;
        }
    }
    @media (min-width: 768px) {
        .v-text-field {
            min-height: 48px !important;
        }
    }
}
</style>
